<template>
    <div class="wraper">
        <div class="wrap-mainer">
            <div class="numsidebar">
                <div class="sidebarmain">
                    <a-tabs default-active-key="tab_active" v-model="type" tab-position="left" type="card" @change="tabChange">
                        <a-tab-pane key="-1" tab="全部编号规则列表"></a-tab-pane>
                        <a-tab-pane key="0" tab="委托单编号"></a-tab-pane>
                        <a-tab-pane key="1" tab="样品编号"></a-tab-pane>
                        <a-tab-pane key="2" tab="人员编号"></a-tab-pane>
                        <a-tab-pane key="3" tab="仪器编号"></a-tab-pane>
                        <a-tab-pane key="4" tab="核酸检验批次编号"></a-tab-pane>
                    </a-tabs>
                </div>
                <div class="addbutton">
                    <a-button type="primary" style="width: 100%" @click="showDrawer({
            id:0,
            num_type:'',
            name:'',
            num_pre:'',
            data_format:'',
            serial_num:'',
            serial_increment:'',
            update_type:'',
            rules_default:'',
            })">添加编号规则
                    </a-button>
                </div>
            </div>

            <div class="list-right wrap-container">
                <!--所有编号规则列表-->
                <div v-if="Number(type) === -1">
                    <table class="layui-table" lay-size="sm">
                        <thead>
                        <tr>
                            <th>编号分类</th>
                            <th>编号名称</th>
                            <th>前缀</th>
                            <th>日期格式</th>
                            <th>流水号</th>
                            <th>流水号增量</th>
                            <th>流水号更新间隔</th>
                            <th>完整编号示例</th>
                            <th>是否为默认规则</th>
                            <th>操作</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item,key) in rulenum_list">
                            <td>{{wordTypeFenlei(item.num_type)}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.num_pre}}</td>
                            <td>{{wordTypeData(item.data_format)}}</td>
                            <td>{{item.serial_num}}</td>
                            <td>{{item.serial_increment}}</td>
                            <td>{{ wordTypeUpate(item.update_type)}}</td>
                            <td>{{item.serialnumber}}</td><!--{{wordTypeMoren(item.rules_default)}}-->
                            <td>
                                <a-radio-group v-model="item.rules_default" @change="onChangeWTD(item)" :disabled="item.rules_default==0">
                                    <a-radio :value="0">是</a-radio>
                                    <a-radio :value="1">否</a-radio>
                                </a-radio-group>
                            </td>

                            <td>

                                <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick(item)">
                                    <template slot="title">
                                        确认删除该项？
                                    </template>
                                    <a-button type="link" class="redcolor">
                                        删除
                                    </a-button>

                                </a-popconfirm>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
                </div>

                <!--委托单编号规则列表-->
                <div v-if="Number(type) === 0">
                    <table class="layui-table" lay-size="sm">
                        <thead>
                        <tr>
                            <th>编号分类</th>
                            <th>编号名称</th>
                            <th>前缀</th>
                            <th>日期格式</th>
                            <th>流水号</th>
                            <th>流水号增量</th>
                            <th>流水号更新间隔</th>
                            <th>完整编号示例</th>
                            <th>是否为默认规则</th>
                            <th>操作</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item,key) in rulenum_list" v-if="item.num_type == 0">
                            <td>{{wordTypeFenlei(item.num_type)}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.num_pre}}</td>
                            <td>{{wordTypeData(item.data_format)}}</td>
                            <td>{{item.serial_num}}</td>
                            <td>{{item.serial_increment}}</td>
                            <td>{{ wordTypeUpate(item.update_type)}}</td>
                            <td>{{item.serialnumber}}</td>

                            <td>
                                <a-radio-group v-model="item.rules_default" @change="onChangeWTD(item)" :disabled="item.rules_default==0">
                                    <a-radio :value="0">是</a-radio>
                                    <a-radio :value="1">否</a-radio>
                                </a-radio-group>

                            </td>
                            <td>
                                <!--                                <a-button type="danger" @click="delClick(item)">删除</a-button>-->
                                <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick(item)">
                                    <template slot="title">
                                        确认删除该项？
                                    </template>
                                    <a-button type="link" class="redcolor">
                                        删除
                                    </a-button>
                                </a-popconfirm>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
                </div>

                <!--样品单编号规则列表-->
                <div v-if="Number(type) === 1">
                    <table class="layui-table" lay-size="sm">
                        <thead>
                        <tr>
                            <th>编号分类</th>
                            <th>编号名称</th>
                            <th>前缀</th>
                            <th>日期格式</th>
                            <th>流水号</th>
                            <th>流水号增量</th>
                            <th>流水号更新间隔</th>
                            <th>完整编号示例</th>
                            <th>是否为默认规则</th>
                            <th>操作</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item,key) in rulenum_list" v-if="item.num_type == 1">
                            <td>{{wordTypeFenlei(item.num_type)}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.num_pre}}</td>
                            <td>{{wordTypeData(item.data_format)}}</td>
                            <td>{{item.serial_num}}</td>
                            <td>{{item.serial_increment}}</td>
                            <td>{{ wordTypeUpate(item.update_type)}}</td>
                            <td>{{item.serialnumber}}</td>
                            <!--                            <td>{{wordTypeMoren(item.rules_default)}}</td>-->
                            <td>
                                <!--                               -->
                                <a-radio-group v-model="item.rules_default" @change="onChangeWTD(item)" :disabled="item.rules_default==0">
                                    <a-radio :value="0">是</a-radio>
                                    <a-radio :value="1">否</a-radio>
                                </a-radio-group>

                            </td>
                            <td>
                                <!--                                <a-button type="danger" @click="delClick(item)">删除</a-button>-->
                                <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick(item)">
                                    <template slot="title">
                                        确认删除该项？
                                    </template>
                                    <a-button type="link" class="redcolor">
                                        删除
                                    </a-button>
                                </a-popconfirm>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
                </div>

                <!--人员编号规则列表-->
                <div v-if="Number(type) === 2">
                    <table class="layui-table" lay-size="sm">
                        <thead>
                        <tr>
                            <th>编号分类</th>
                            <th>编号名称</th>
                            <th>前缀</th>
                            <th>日期格式</th>
                            <th>流水号</th>
                            <th>流水号增量</th>
                            <th>流水号更新间隔</th>
                            <th>完整编号示例</th>
                            <th>是否为默认规则</th>
                            <th>操作</th>
                        </tr>
                        </thead>
                        <tr v-for="(item,key) in rulenum_list" v-if="item.num_type == 2">
                            <td>{{wordTypeFenlei(item.num_type)}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.num_pre}}</td>
                            <td>{{wordTypeData(item.data_format)}}</td>
                            <td>{{item.serial_num}}</td>
                            <td>{{item.serial_increment}}</td>
                            <td>{{ wordTypeUpate(item.update_type)}}</td>
                            <td>{{item.serialnumber}}</td>
                            <!--                        <td>{{wordTypeMoren(item.rules_default)}}</td>-->
                            <td>
                                <a-radio-group v-model="item.rules_default" @change="onChangeWTD(item)" :disabled="item.rules_default==0">
                                    <a-radio :value="0">是</a-radio>
                                    <a-radio :value="1">否</a-radio>
                                </a-radio-group>
                            </td>
                            <td>
                                <!--                                <a-button type="danger" @click="delClick(item)">删除</a-button>-->
                                <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick(item)">
                                    <template slot="title">
                                        确认删除该项？
                                    </template>
                                    <a-button type="link" class="redcolor">
                                        删除
                                    </a-button>
                                </a-popconfirm>
                            </td>
                        </tr>
                    </table>
                    <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
                </div>

                <!--仪器编号规则列表-->
                <div v-if="Number(type) === 3">
                    <table class="layui-table" lay-size="sm">
                        <thead>
                        <tr>
                            <th>编号分类</th>
                            <th>编号名称</th>
                            <th>前缀</th>
                            <th>日期格式</th>
                            <th>流水号</th>
                            <th>流水号增量</th>
                            <th>流水号更新间隔</th>
                            <th>完整编号示例</th>
                            <th>是否为默认规则</th>
                            <th>操作</th>
                        </tr>
                        </thead>
                        <tr v-for="(item,key) in rulenum_list" v-if="item.num_type == 3">
                            <td>{{wordTypeFenlei(item.num_type)}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.num_pre}}</td>
                            <td>{{wordTypeData(item.data_format)}}</td>
                            <td>{{item.serial_num}}</td>
                            <td>{{item.serial_increment}}</td>
                            <td>{{ wordTypeUpate(item.update_type)}}</td>
                            <td>{{item.serialnumber}}</td>
                            <!--                        <td>{{wordTypeMoren(item.rules_default)}}</td>-->
                            <td>
                                <a-radio-group v-model="item.rules_default" @change="onChangeWTD(item)" :disabled="item.rules_default==0">
                                    <a-radio :value="0">是</a-radio>
                                    <a-radio :value="1">否</a-radio>
                                </a-radio-group>
                            </td>
                            <td>
                                <!--                                <a-button type="danger" @click="delClick(item)">删除</a-button>-->
                                <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick(item)">
                                    <template slot="title">
                                        确认删除该项？
                                    </template>
                                    <a-button type="link" class="redcolor">
                                        删除
                                    </a-button>
                                </a-popconfirm>
                            </td>
                        </tr>
                    </table>
                    <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
                </div>

                <!--核酸检验批次编号-->
                <div v-if="Number(type) === 4">
                    <table class="layui-table" lay-size="sm">
                        <thead>
                        <tr>
                            <th>编号分类</th>
                            <th>编号名称</th>
                            <th>前缀</th>
                            <th>日期格式</th>
                            <th>流水号</th>
                            <th>流水号增量</th>
                            <th>流水号更新间隔</th>
                            <th>完整编号示例</th>
                            <th>是否为默认规则</th>
                            <th>操作</th>
                        </tr>
                        </thead>
                        <tr v-for="(item,key) in rulenum_list" v-if="item.num_type == 4">
                            <td>{{wordTypeFenlei(item.num_type)}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.num_pre}}</td>
                            <td>{{wordTypeData(item.data_format)}}</td>
                            <td>{{item.serial_num}}</td>
                            <td>{{item.serial_increment}}</td>
                            <td>{{ wordTypeUpate(item.update_type)}}</td>
                            <td>{{item.serialnumber}}</td>
                            <td>
                                <a-radio-group v-model="item.rules_default" @change="onChangeWTD(item)" :disabled="item.rules_default==0">
                                    <a-radio :value="0">是</a-radio>
                                    <a-radio :value="1">否</a-radio>
                                </a-radio-group>
                            </td>
                            <td>
                                <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick(item)">
                                    <template slot="title">
                                        确认删除该项？
                                    </template>
                                    <a-button type="link" class="redcolor">
                                        删除
                                    </a-button>
                                </a-popconfirm>
                            </td>
                        </tr>
                    </table>
                    <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
                </div>


            </div>
        </div>
        <!-----打开新建、编辑编号规则窗口------>
        <div v-if="edit_info">
            <bianhaoguizeCreate :edit_info="edit_info" :edit_time="edit_time" :close="onCloseDrawer" :visible="drawer_visible"></bianhaoguizeCreate>
        </div>

    </div>
</template>

<script>
    import bianhaoguizeCreate from '../../components/drawer/bianhaoguize-create/bianhaoguize-create.vue';

    export default {
        name: "numguize",
        components: {
            bianhaoguizeCreate
        },
        data() {
            return {
                page: 1,
                count: 0,
                pagesize: 0,
                id: '',
                rules_default: '',
                num_type: '',
                //编号规则列表
                rulenum_list: [],
                //左侧tab切换
                type: '-1',
                tab_active: '-1',
                // 定义控制抽屉显示的变量
                edit_time: 0,
                edit_info: false,
                drawer_visible: false,//新建编号规则
                page_show: false,
            }
        },
        watch: {
            $route: {
                handler() {
                    this.page = Number(this.$route.query.page) || 1;
                    this.type = this.$route.query.type || '-1';
                    this.Rulenumlist()// w编号规则列表
                    setTimeout(() => {
                        this.page_show = true
                    }, 1)
                },
                deep: true
            }
        },
        mounted() {
            this.page = Number(this.$route.query.page) || 1;
            this.type = this.$route.query.type || '-1';
            this.Rulenumlist()// w编号规则列表
        },

        methods: {

            //是否设为默认编号
            onChangeWTD(item) {
                let data = {
                    id: item.id,
                    rules_default: item.rules_default,
                    num_type: item.num_type,
                }
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.$api('Set_rulenum'),
                    data: data
                }).then((response) => {
                    console.log('response-----------------')
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('设置成功！')
                            this.Rulenumlist()// w编号规则列表
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            //删除
            delClick(item) {
                console.log('url-----------------', this.$api('Del_rulenum'))
                this.$sa0.post({
                    url: this.$api('Del_rulenum'),
                    data: {
                        id: item.id,
                    }
                }).then((response) => {
                    console.log('response-----------------')
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Rulenumlist()// w编号规则列表
                            console.log(item)
                            console.log(this.rulenum_list)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 类型转换-编号分类
            wordTypeFenlei(num_type) {
                let type_arr = ['委托单编号', '样品编号', '人员编号', '仪器编号', '核酸检验批次编号']
                if (type_arr.length >= Number(status)) {
                    return type_arr[Number(num_type) - 0]
                } else {
                    return num_type
                }
            },
            // 类型转换-日期格式
            wordTypeData(data_format) {
                let type_arr = ['年月日时分秒 - 例：20211109203736', '年月日时分 - 例：202111092037', '年月日时 - 例：2021110920', '年月日 - 例：20211109', '年月 - 例：202111', '年 - 例：2021']
                if (type_arr.length >= Number(status)) {
                    return type_arr[Number(data_format) - 0]
                } else {
                    return data_format
                }
            },
            // 类型转换-时间间隔
            wordTypeUpate(update_type) {
                let type_arr = ['不更新', '按年更新', '按月更新', '按天更新']
                if (type_arr.length >= Number(update_type)) {
                    return type_arr[Number(update_type) - 0]
                } else {
                    return update_type
                }
            },
            // 类型转换-是否默认
            wordTypeMoren(rules_default) {
                let type_arr = ['是', '否']
                if (type_arr.length >= Number(rules_default)) {
                    return type_arr[Number(rules_default) - 0]
                } else {
                    return rules_default
                }
            },

            // w编号规则列表
            Rulenumlist() {
                this.$sa0.post({
                    url: this.$api('Rulenumlist'),
                    data: {
                        num_type: this.type,
                        page: this.page,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.rulenum_list = response.data.return.list;
                            console.log(this.rulenum_list)
                            console.log(JSON.stringify(this.rulenum_list))
                            //console.log(this.count,response.data.result)
                            //return
                            this.count = Number(response.data.return.count);//分页
                            this.pagesize = response.data.return.pagesize;//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })

            },

            // 分页
            onChange(page) {
                this.$router.push({
                    query: {
                        page: page,
                        type: this.type
                    }
                })
            },


            // tab切换
            tabChange(key) {
                // console.log(key)
                // return
                this.$router.push({
                    query: {
                        page: 1,
                        type: key,
                        //status: this.status,
                        //pinyin: this.pinyin
                    }
                })
            },

            // 定义 打开抽屉时的 函数 新建编号规则
            showDrawer(item) {
                this.edit_time = new Date() / 1;
                this.edit_info = item
                this.drawer_visible = true;
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawer() {
                this.Rulenumlist()// w编号规则列表
                this.drawer_visible = false;
            },
        },

    }
</script>

<style>
    /*table {*/
    /*    margin: 15px 0;*/
    /*    width: 100%;*/
    /*    border-top: solid 1px #d9d9d9;*/
    /*    border-left: solid 1px #d9d9d9;*/
    /*}*/
    /*table td, table th {*/
    /*    border-right: solid 1px #d9d9d9;*/
    /*    border-bottom: solid 1px #d9d9d9;*/
    /*    padding: 8px 15px;*/
    /*    line-height: 1;*/
    /*}*/
    /*table th {*/
    /*    background: #f5f5f5*/
    /*}*/
    /*table tr:hover {*/
    /*    cursor: pointer;*/
    /*    background: #f5f5f5*/
    /*}*/
    /*table button{ margin: 0 5px}*/


    .list-left{
        background: #f9f9f9;
        min-height: 850px;
        border: 1px solid #eeeeee;
        border-right: none;
        height: 100%;
        padding: 20px 0 0 10px
        }

    .list-right{
        padding-left: 220px;

        }

    .ant-tabs .ant-tabs-left-bar{
        float: right !important;
        margin-right: -1px;
        margin-bottom: 0;
        border-right: 1px solid #e8e8e8;
        width: 100% !important;
        }

    .ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active{
        margin-right: -1px;
        padding-right: 40px !important;
        font-weight: bold;
        }

    .addbutton{
        width: 100%;
        text-align: center; margin-left: -5px;
        }

    .wraper{ width: 100%; height: 100%; display: flex;}

    .wrap-mainer{ flex: 1;display: flex; }

    .numsidebar{ position: fixed; width: 200px; display: flex; flex-direction: column; background: #f9f9f9; padding: 10px; padding-right: 0; height: calc(100vh - 120px)}

    .sidebarmain{ flex: 1; overflow-y: auto}

    .wrap-container{ flex: 1; overflow-y: auto}

</style>
