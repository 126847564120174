<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer :title="`${info.id===0?'新建':'编辑'}编码规则`" width="32%" placement="right" :closable="true"
                  :visible="visible" @close="onClose">
            <div>
                <a-form id="components-form-demo-validate-other" :label-col="{ span: 6, offset: 0 }"
                        :wrapper-col="{ span:10 }" v-bind="formItemLayout" @submit="handleSubmit">
                    <a-form-item label="编号分类">
                        <a-select default-value="0" v-model="info.num_type" @change="handleChange">
                            <a-select-option :value="0">委托单编号</a-select-option>
                            <a-select-option :value="1">样品编号</a-select-option>
                            <a-select-option :value="2">人员编号</a-select-option>
                            <a-select-option :value="3">仪器编号</a-select-option>
                            <a-select-option :value="4">核酸检验批次编号</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item label="编号规则名称">
                        <a-input v-model="info.name"/>
                    </a-form-item>

                    <a-form-item label="编号前缀">
                        <a-input v-model="info.num_pre"/>
                    </a-form-item>

                    <a-form-item label="日期格式">
                        <a-select default-value="0" v-model="info.data_format" @change="handleChange">
                            <a-select-option :value="0">年月日时分秒 - 例：20211109203736</a-select-option>
                            <a-select-option :value="1">年月日时分 - 例：202111092037</a-select-option>
                            <a-select-option :value="2">年月日时 - 例：2021110920</a-select-option>
                            <a-select-option :value="3">年月日 - 例：20211109</a-select-option>
                            <a-select-option :value="4">年月 - 例：202111</a-select-option>
                            <a-select-option :value="5">年 - 例：2021</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item label="流水号">
                        <a-input v-model="info.serial_num"/>
                    </a-form-item>

                    <a-form-item label="流水号增量">
                        <a-input v-model="info.serial_increment"/>
                    </a-form-item>

                    <a-form-item label="流水号更新间隔">
                        <a-select default-value="0" v-model="info.update_type" @change="handleChange">
                            <template>
                                <a-select-option v-for="(i ,k) in update_typeList" :value="i.value">{{ i.name }}</a-select-option>
                            </template>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="是否设为默认规则" :label-col="{ span: 6, offset: 0 }" :wrapper-col="{ span:16 }">
                        <a-radio-group v-model="info.rules_default" @change="onChange">
                            <a-radio :value="0">是</a-radio>
                            <a-radio :value="1">否</a-radio>
                        </a-radio-group><span style="color: #ff4d4f">*每个类别下只能设置一个默认模板</span>
                    </a-form-item>

                    <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
                        <a-button type="primary" @click="doClick()">保存</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </a-drawer>
    </div>
</template>
<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'edit_info', 'edit_time'],
        name: 'about',
        data() {
            return {
                info: {
                    id: 0,
                    num_type: '',
                    name: '',
                    num_pre: '',
                    data_format: '',
                    serial_num: '',
                    serial_increment: '',
                    update_type: '',
                    rules_default: '',
                },
                do_type: false,
                value: '',
                formItemLayout: {
                    labelCol: {span: 6},
                    wrapperCol: {span: 14},
                },
            }
        },
        computed: {
            update_typeList() {
                let list = [{
                    value: 0,
                    name: '不更新'
                }, {
                    value: 4,
                    name: '按年更新'
                }, {
                    value: 3,
                    name: '按月更新'
                }, {
                    value: 1,
                    name: '按日更新'
                }]
                switch (this.info.data_format) {

                    case 0:
                        list = [{
                            value: 0,
                            name: '不更新'
                        }, {
                            value: 4,
                            name: '按年更新'
                        }, {
                            value: 3,
                            name: '按月更新'
                        }, {
                            value: 1,
                            name: '按日更新'
                        }]
                        break;
                    case 1:
                        list = [{
                            value: 0,
                            name: '不更新'
                        }, {
                            value: 4,
                            name: '按年更新'
                        }, {
                            value: 3,
                            name: '按月更新'
                        }, {
                            value: 1,
                            name: '按日更新'
                        }]
                        break;
                    case 2:
                        list = [{
                            value: 0,
                            name: '不更新'
                        }, {
                            value: 4,
                            name: '按年更新'
                        }, {
                            value: 3,
                            name: '按月更新'
                        }, {
                            value: 1,
                            name: '按日更新'
                        }]
                        break;
                    case 3:
                        list = [{
                            value: 0,
                            name: '不更新'
                        }, {
                            value: 4,
                            name: '按年更新'
                        }, {
                            value: 3,
                            name: '按月更新'
                        }, {
                            value: 1,
                            name: '按日更新'
                        }]
                        break;
                    case 4:
                        list = [{
                            value: 0,
                            name: '不更新'
                        }, {
                            value: 4,
                            name: '按年更新'
                        }, {
                            value: 3,
                            name: '按月更新'
                        }]
                        break;
                    case 5:
                        list = [{
                            value: 0,
                            name: '不更新'
                        }, {
                            value: 4,
                            name: '按年更新'
                        }]
                        break;
                }
                return list
            }
        },
        watch: {
            edit_time() {
                this.info = JSON.parse(JSON.stringify(this.edit_info))
            },
        },

        mounted() {
            this.info = JSON.parse(JSON.stringify(this.edit_info))
        },

        methods: {
            doClick() {
                let data = {
                    id: this.info.id,
                    num_type: this.info.num_type,
                    name: this.info.name,
                    num_pre: this.info.num_pre,
                    data_format: this.info.data_format,
                    serial_num: this.info.serial_num,
                    serial_increment: this.info.serial_increment,
                    update_type: this.info.update_type,
                    rules_default: this.info.rules_default,
                }
                console.log(data)
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.info.id !== 0 ? this.$api('Edit_rulenum') : this.$api('Create_rulenum'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            this.close()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            //单选
            onChange(e) {
                console.log('radio checked', e.target.value);
            },
            //select
            handleChange(value) {
                console.log(`selected ${value}`);
            },
            //form
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields((err, values) => {
                    if (!err) {
                        console.log('Received values of form: ', values);
                    }
                });
            },
            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },

        },
    }
</script>

<style scoped>
    table {
        margin: 15px auto 15px 0;
        width: 90%;
        border-top: solid 1px #d9d9d9;
        border-left: solid 1px #d9d9d9;
    }

    table td, table th {
        border-right: solid 1px #d9d9d9;
        border-bottom: solid 1px #d9d9d9;
        padding: 10px 15px;
        line-height: 1;
    }

    table th {
        padding: 12px 15px;
        background: #f5f5f5
    }

    table td.tag {
        background: #f5f5f5;
        width: 18%;
        text-align: right;
        font-weight: bold
    }

    #components-form-demo-validate-other .dropbox {
        height: 180px;
        line-height: 1.5;
    }
</style>
